import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/SEO/SEO"
import Layout from "../components/Layout/Layout"
import BlockContent from "../components/Sanity/blockContent"
import Logo from "../components/Parts/logo"
import {
  Container,
  Section,
  Column,
  Title,
  Columns,
  Hero,
  HeroBody,
} from "bloomer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ColumnTemplate from "../components/Index/threeCollumns"
import { faChevronCircleRight } from "../fontAwesome/pro-duotone-svg-icons"

const IndexPage = ({ data }) => {
  const index = data.sanityFrontPage

  return (
    <Layout>
      <SEO title="Home" />
      {/*Bulma section, ikke Material UI section*/}
      <main>
        <Hero isFullHeight="true" className="introHero">
          <HeroBody className="introHero">
            <Container className="indexStartContainer">
              <Columns className="is-centered">
                <Column className="is-narrow introImage">
                  <Logo className="frontPageLogo" />
                </Column>
                <Column className="introText">
                  <div className="boxContainer">
                    <Title
                      className="is-1 title GUDCfontSize introTitle"
                      id="main"
                    >
                      {index.missionTitle}
                    </Title>
                    <BlockContent blocks={index._rawMissionStatement} />
                    <Link class="space-please general-links" to="/aboutUs/">
                      <FontAwesomeIcon
                        icon={faChevronCircleRight}
                        className="faIconColors linkIcon"
                        alt="Icon pointer right"
                      />{" "}
                      About us
                    </Link>
                  </div>
                </Column>
              </Columns>
            </Container>
          </HeroBody>
        </Hero>
        <Section className="resquestBackground">
          <ColumnTemplate data={data} />
        </Section>
        {/* <Section className="missionHero" isSize="small">
            <Container hasTextAlign="centered">
              <h1 className="title is-1">{index.focusTitle}</h1>
              <FontAwesomeIcon
                icon={faGlobeEurope}
                className="euIcon fa-10x missionIcon"
              />
              <div className="missionBlock">
                <BlockContent blocks={index._rawMissionFocus} />
              </div>
            </Container>
          </Section> */}
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    sanityFrontPage {
      _rawMissionStatement
      _rawFirstColumn
      _rawSecondColumn
      _rawThirdColumn
      thirdColumnTitle
      missionTitle
      secondColumnTitle
      firstColumnTitle
    }
  }
`
