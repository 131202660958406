import React from "react"
import { Column, Columns, Title, Container } from "bloomer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faGlobe,
  faEquals,
  faUniversalAccess,
} from "@fortawesome//free-solid-svg-icons"

const BlockContent = require("@sanity/block-content-to-react")

const ThreeColumns = ({ data }) => {
  const columnData = data.sanityFrontPage

  return (
    <>
      <Container>
        <Columns className="" isSize="small">
          <Column className="margins has-text-centered">
            <FontAwesomeIcon
              icon={faEquals}
              className="faIconColor fa-5x"
              alt="Icon pointer right"
            />
            <Title tag="h2" className="is-2">
              {columnData.firstColumnTitle}
            </Title>{" "}
            {console.log(data)}
            <BlockContent blocks={columnData._rawFirstColumn} />
          </Column>
          <Column className="margins has-text-centered middleColumn">
            <FontAwesomeIcon
              icon={faUniversalAccess}
              className="faIconColor fa-5x"
              alt="Icon pointer right"
            />
            <Title tag="h2" className="is-2">
              {columnData.secondColumnTitle}
            </Title>{" "}
            <BlockContent blocks={columnData._rawSecondColumn} />
          </Column>

          <Column className="margins has-text-centered">
            <FontAwesomeIcon
              icon={faGlobe}
              className="faIconColor fa-5x"
              alt="Icon pointer right"
            />
            <Title tag="h2" className="is-2">
              {columnData.thirdColumnTitle}
            </Title>{" "}
            <BlockContent blocks={columnData._rawThirdColumn} />
          </Column>
        </Columns>
      </Container>
    </>
  )
}

export default ThreeColumns
